import "../../../styles/scss/vehicleDetails/colorSelector.scss";
import AppSettings from "../../../appsettings.js";
import applyMarkdownRemoveDisclaimers from '../../utils/applyMarkdownRemoveDisclaimers';
import { useMemo } from "react";

const ColorSelector = ({ 
    options, 
    availableOptions, 
    selectedOption, 
    onSelect, 
    type = 'exterior', 
    s3Path,
    pageTitle,
    model,
    year,
    trim, 
    clickAnalytics = () => {} 
}) => {
    const key = type === 'exterior' ? 'paintCode': 'code';
    const name = type === 'exterior' ? 'paintCodeName': 'name';

    const getAvailable = (key) => {
        return availableOptions?.includes(key);
    }

    const getClasses = (itemKey) => {
        let className = '';

        if (getAvailable(itemKey)) className += 'available';
        if (selectedOption?.[key] === itemKey) className += ' selected';

        return className;
    }

    // Decently reliable way to pick out 'bright' colors that would benefit from a darker checkmark
    const getCheckMark = (extColor) => {
        var color = (extColor.charAt(0) === '#') ? extColor.substring(1, 7) : extColor;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
          'check-dark.svg' : 'check-white.svg';
    }

    const getBackground = (option) => {
        if (type === "exterior") return  (
            { 
                backgroundColor: option?.hexCode, 
                '--check-icon' : `url(${AppSettings.AWSImgRepo.resources}en/${getCheckMark(option?.hexCode)})` 
            }
        );
        
        let bgPath;
        let interiorCheckMark = 'check-white.svg';
        if(option?.swatch) {
            bgPath = `${AppSettings.AWSImgRepo.vehicle}${s3Path}${option?.swatch}`;
        } else {
            bgPath = `${AppSettings.AWSImgRepo.resources}en/missingSwatch.png`;
            interiorCheckMark = 'check-dark.svg';
        }

        return ({ 
            backgroundImage: `url(${encodeURI(bgPath)})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            '--check-icon' : `url(${AppSettings.AWSImgRepo.resources}en/${interiorCheckMark})`
        });
    }

    const handleOnSelect = (option) => {
        if (getAvailable(option?.[key])) {
            onSelect(option)

            clickAnalytics({
                page: pageTitle,
                resourceType: type,
                resourceValue: option?.[name],
                modelName: model,
                modelYear: year,
                trim: trim
            })
        }
    }

    const formatOptionTitle = (option) => {
        let title = `${option?.[name]} (${option?.[key]})`;
        if (option?.paintCodeCharge) title += ` - PC ${option?.paintCodeCharge}`
        return applyMarkdownRemoveDisclaimers(title);
    }

    const selectedOptionTitle = useMemo(() => {
       if (selectedOption) return formatOptionTitle(selectedOption)
       return '';
    }, [selectedOption]);


    return <div className="color-selector-wrapper">
        <div className="color-selector-header">
            <h3>{type}</h3>
            <span>{selectedOptionTitle}</span>
        </div>
        <ul className="color-selector-body">
            {options?.map(c => 
                <li className={getClasses(c[key])}  onClick={() => handleOnSelect(c)}>
                    <div style={getBackground(c)}></div>
                    <span className="color-item-tooltip">{formatOptionTitle(c)}</span>
                </li>
            )}
        </ul>
    </div>
}

export default ColorSelector;