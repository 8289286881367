/* eslint-disable class-methods-use-this */
import { Component } from 'react';
import getDisclaimers from '../../services/disclaimersService.js';
import { DisclaimersTemplate } from './disclaimers.tpl';
export default class DisclaimersComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      selectedDisclaimer: undefined,
    };
    this.currentTemplate = '';
    this.loading = true;
    this.disclaimers = {};
    this.nonTemplateDisclaimers = {};
    this.onOpenModal = this.onOpenModal.bind(this);
    this.loadAndSetDisclaimers = this.loadAndSetDisclaimers.bind(this);
    this.loadAndSetNonTemplateDisclaimers = this.loadAndSetNonTemplateDisclaimers.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onAfterOpenModal = this.onAfterOpenModal.bind(this);
  }

  async componentWillReceiveProps(nextProp) {
    // if the disclaimer is not a disclaimer in a search result, load the disclaimers if props change
    if (!this.props.isSearchDisclaimer && !this.props.isHomeDisclaimer) {
      await this.loadAndSetDisclaimers(nextProp.template);
      this.loadAndSetNonTemplateDisclaimers();
    }
  }

  async loadAndSetDisclaimers(template) {
    if(template) {
      const disclaimerResult = await getDisclaimers(template);
      this.disclaimers = disclaimerResult.disclaimers;
    }
  }

  // TODO: will need to be updated with call to EFC endpoint
  loadAndSetNonTemplateDisclaimers() {

    const baseMSRPDisclaimer = {'&dagger;':`Base MSRP excludes manufacturer, distributor and dealer options, taxes, title and license and dealer fees and charges. 
    Also excludes the Delivery, Processing and Handling Fee of $1,095 for Cars (GR 86, Avalon, Avalon HV, Camry, Camry HV, Corolla, Corolla HV, Corolla HB, 
    GR Corolla, Mirai, Prius, Prius Prime, GR Supra, Toyota Crown), $1,335 for SUV/Van/Small Truck (4Runner, Corolla Cross HV, C-HR, Highlander, Highlander HV, RAV4, 
    RAV4 HV, RAV4 Prime, Sienna, Tacoma, Venza, BZ4X), [$1,595 for Large SUVs (Sequoia), and $1,795 for Large Truck (Tundra and Tundra Hybrid). (Historically, 
    vehicle manufacturers and distributors have charged a separate fee for processing, handling and delivering vehicles to dealerships. Toyota's charge for these 
    services is called the ""Delivery, Processing and Handling Fee"" and is based on the value of the processing, handling and delivery services Toyota provides as 
    well as Toyota's overall pricing structure and may be subject to change at any time. Toyota may make a profit on the Delivery, Processing and Handling Fee.) The 
    Delivery, Processing and Handling Fee in AL, AR, FL, GA, LA, MS, NC, OK, SC and TX will be higher. Dealer price will vary. `};

    const startingMSRPDisclaimer = {'&dagger;': `Starting MSRP excludes manufacturer, distributor and dealer options, taxes, title and license and dealer fees and charges.
    Also excludes the Delivery, Processing and Handling Fee of $1,095 for Cars (GR 86, Avalon, Avalon HV, Camry, Camry HV, Corolla, Corolla HV, Corolla HB, 
    GR Corolla, Mirai, Prius, Prius Prime, GR Supra, Toyota Crown), $1,335 for SUV/Van/Small Truck (4Runner, Corolla Cross HV, C-HR, Highlander, Highlander HV, RAV4, 
    RAV4 HV, RAV4 Prime, Sienna, Tacoma, Venza, BZ4X), [$1,595 for Large SUVs (Sequoia), and $1,795 for Large Truck (Tundra and Tundra Hybrid). (Historically, 
    vehicle manufacturers and distributors have charged a separate fee for processing, handling and delivering vehicles to dealerships. Toyota's charge for these 
    services is called the ""Delivery, Processing and Handling Fee"" and is based on the value of the processing, handling and delivery services Toyota provides as 
    well as Toyota's overall pricing structure and may be subject to change at any time. Toyota may make a profit on the Delivery, Processing and Handling Fee.) The 
    Delivery, Processing and Handling Fee in AL, AR, FL, GA, LA, MS, NC, OK, SC and TX will be higher. Dealer price will vary. `};

    if(this.props.addBaseMSRPDisclaimer) {
      this.nonTemplateDisclaimers = (baseMSRPDisclaimer);
    }

    if(this.props.addStartingMSRPDisclaimer) {
      this.nonTemplateDisclaimers = (startingMSRPDisclaimer);
    }
    
  }

  async componentDidMount() {
    // if the disclaimer is not a disclaimer in a search result, load the disclaimers
    if (!this.props.isSearchDisclaimer) {
      await this.loadAndSetDisclaimers(this.props.template);
      this.loadAndSetNonTemplateDisclaimers();
    }
  }

  async onOpenModal(selectedDisclaimer) {
    // load the disclaimers the first time the user clicks on a disclaimer
    // for all disclaimers in the search results
    if (this.props.isSearchDisclaimer) {
      if (this.currentTemplate !== this.props.template) {
        this.currentTemplate = this.props.template;
        await this.loadAndSetDisclaimers(this.props.template)
        this.loadAndSetNonTemplateDisclaimers();
      }
    }
    this.setState({
      modalOpen: true,
      selectedDisclaimer,
    });
    if (this.props.analyticsOnSelection) {
      this.props.analyticsOnSelection(selectedDisclaimer);
    }
  }

  onAfterOpenModal() {
    this.scrollToSelectedDisclaimer();
    this.lockDocumentBodyWhileDisclaimersAreOpen();
  }

  scrollToSelectedDisclaimer() {
    const eventReference = `disclaimer-number-${this.state.selectedDisclaimer}`;
    const disclaimerEl = document.getElementById(eventReference);
    disclaimerEl.scrollIntoView({ block: 'start' });
  }

  lockDocumentBodyWhileDisclaimersAreOpen() {
    document.body.classList.add('body-disclaimers-open');
  }

  onCloseModal() {
    this.animateCloseByAddingNewCSSClassToModalOverlay();
    this.removeModalFromPage();
  }

  animateCloseByAddingNewCSSClassToModalOverlay() {
    var modal = document.getElementsByClassName('disclaimer-modal-overlay');
    modal[0].classList.add('disclaimer-modal-overlay-close');
  }

  removeModalFromPage() {
    setTimeout(() => {
      this.setState({ modalOpen: false });
      this.unlockDocumentBodyWhenDisclaimersClose();
    }, 300);
  }

  unlockDocumentBodyWhenDisclaimersClose() {
    document.body.classList.remove('body-disclaimers-open');
  }

  render() {
    return DisclaimersTemplate(this);
  }
}
