import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import '../../styles/css/footer.css';
import { GlobalAnalytics } from '../../analytics';
import { postUserActivityRecord } from '../../services/userActivityService.js';
import getNavigation from '../../services/navigationService';
import AppSettings from '../../appsettings';



const analytics = new GlobalAnalytics();

const FooterComponent = () => {
  const [certificationSection, setCertificationSection] = useState(null);
  const [engageAppSection, setEngageAppSection] = useState(null);
  const [engageResourcesSection, setEngageResourcesSection] = useState(null);
  const [externalResourcesSection, setExternalResourcesSection] = useState(null);
  const [termsAndConditions, setTermsAndConditions] = useState(null);
  const [privacyLinks, setPrivacyLinks] = useState(null);

  const getFooterNavigation = () => new Promise((resolve, reject) => {
    getNavigation('footer')
      .then((footerNavigation) => {
        setCertificationSection(footerNavigation.routes.sections.certification);
        setEngageAppSection(footerNavigation.routes.engageApp);
        setEngageResourcesSection(footerNavigation.routes.sections.engageResources);
        setExternalResourcesSection(footerNavigation.routes.sections.externalResources);
        setTermsAndConditions(footerNavigation.routes.termsAndConditions);
        setPrivacyLinks(footerNavigation.routes.privacyLinks);
      }).catch((error) => {
        reject(error);
      });
  });

  const footerContentClick = (event, category) => {
    if (event.target.innerText === 'Mobility Center') {
      postUserActivityRecord('/mobilityCenter');
    }
    if (event.target.innerText === 'TCUV Hub') {
      postUserActivityRecord('https://hub.toyota.com/?option=saml_user_login&idp=Tess');
    }
    analytics.fireOnClick({
      tag: '28.2',
      container: 'Footer',
      category,
      mediaTitle: ' ',
      label: event.target.innerText,
      contentTitle: ' ',
    });
  };

  useEffect(() => {
    getFooterNavigation();
  }, []);

  const appLinks = engageAppSection && (
    <React.Fragment>
      {engageAppSection.appStore && (
      <a
        onClick={event => footerContentClick(event, engageAppSection.appStore.analyticsTag)}
        href={engageAppSection.appStore.href}
        target={engageAppSection.appStore.target}
      >
        <img
          className="app-store-link"
          alt={engageAppSection.appStore.alt}
          src={`${AppSettings.AWSImgRepo.appLinks}${engageAppSection.appStore.url}`}
        />
      </a>)}
      {engageAppSection.googlePlay && (
      <a
        onClick={event => footerContentClick(event, engageAppSection.googlePlay.analyticsTag)}
        href={engageAppSection.googlePlay.href}
        target={engageAppSection.googlePlay.target}
      >
        <img
          className="google-play-link"
          alt={engageAppSection.googlePlay.alt}
          src={`${AppSettings.AWSImgRepo.appLinks}${engageAppSection.googlePlay.url}`}
        />
      </a>)}
    </React.Fragment>
  );
  const engageResourcesLinks = engageResourcesSection && engageResourcesSection.links.map((skill, index) => (
    <li key={index}>
      <a
        className="link"
        onClick={event => footerContentClick(event, engageResourcesSection.analyticsTag)}
        href={skill.href}
        target={engageResourcesSection.target}
      >
        {skill.name}
      </a>
    </li>
  ));
  const externalResourcesLinks = externalResourcesSection && externalResourcesSection.links.map((product, index) => (
    <li key={index}>
      <a
        className="link"
        onClick={event => footerContentClick(event, externalResourcesSection.analyticsTag)}
        href={product.href}
        target={externalResourcesSection.target}
      >
        {product.name}
      </a>
    </li>
  ));
  const certificationLinks = certificationSection && certificationSection.links.map((certification, index) => (
    <li key={index}>
      <a
        className="link"
        onClick={event => footerContentClick(event, certificationSection.analyticsTag)}
        href={certification.href}
        target={certificationSection.target}
      >
        {certification.name}
      </a>
    </li>
  ));
  const termsLinks = termsAndConditions && (
    <React.Fragment>
      {termsAndConditions.terms && (
      <a
        onClick={event => footerContentClick(event, termsAndConditions.terms.analyticsTag)}
        href={termsAndConditions.terms.href}
        target={termsAndConditions.terms.target}
      >
        {termsAndConditions.terms.name}
      </a>
      )}
    </React.Fragment>
  );
  const privacyNotice =  privacyLinks && (
    <React.Fragment>
      <a
        onClick={event => footerContentClick(event,privacyLinks.privacyNotice.analyticsTag)}
        href={privacyLinks.privacyNotice.href}
        target={privacyLinks.privacyNotice.target}
      >
        {privacyLinks.privacyNotice.name}
      </a>
    </React.Fragment>
  );
  const privacyChoices = privacyLinks && (
    <React.Fragment>
      <a
        onClick={event => footerContentClick(event, privacyLinks.privacyChoices.analyticsTag)}
        href={privacyLinks.privacyChoices.href}
        target={privacyLinks.privacyChoices.target}
      >
        <span>
          {privacyLinks.privacyChoices.name}
        </span>
        <img
          className='privacy-icon'
          src={AppSettings.AWSImgRepo.resources + `en/privacyoption.svg`}
          alt={privacyLinks.privacyChoices.name}
        />
      </a>
    </React.Fragment>
  )

  if (window.ReactNativeWebView) {
    return <div className="hybrid-app-buffer" />;
  }
  return (
    <footer className="footer">
      <div className='footer-header'>
        {privacyChoices}
      </div>
      <div className="footer-sections">
        <div className="engage-resources-section">
          <div className="title">
            {engageResourcesSection?.sectionHeader}
          </div>
          <ul className="list">
            {engageResourcesLinks}
          </ul>
        </div>
        <div className="external-resources-section">
          <div className="title">
            {externalResourcesSection?.sectionHeader}
          </div>
          <ul className="list">
            {externalResourcesLinks}
          </ul>
        </div>
        <div className="certification-section">
          <div className="title">
            {certificationSection?.sectionHeader}
          </div>
          <ul className="list">
            {certificationLinks}
          </ul>
        </div>
        <div className="engage-app">
          <div className="cta">
            {engageAppSection?.header}
          </div>
          <div className="app-links">
            {appLinks}
          </div>
        </div>
        <div className="terms-and-conditions">
          <div className="terms">
            {termsLinks}
            {privacyNotice}
          </div>
          <div className="copyright">
            {termsAndConditions?.copyright}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default injectIntl(FooterComponent);
