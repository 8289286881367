import getAlternativeVehicleNames from "../../services/alternativeVehicleNames";

export function getUrlModelName(modelName) {
  if (!modelName) {
    return null;
  }

  switch (modelName.toLowerCase()) {
    case "c-hr":
      modelName = "CHR";
      break;
    case "prius c": // NOTE that for new vehicles we will need to also update subheader.component:24-45
    case "prius *c*":
      modelName = "PRIUSC";
      break;
    case "prius v":
    case "prius *v*":
      modelName = "PRIUSV";
      break;
    case "land cruiser":
      modelName = "LANDCRUISER";
      break;
    case "prius prime":
      modelName = "PRIME";
      break;
    case "corolla im":
      modelName = "COROLLAIM";
      break;
    case "yaris ia":
      modelName = "YARISIA";
      break;
    case "corolla cross":
      modelName = "COROLLACROSS";
      break;
    case "corolla hatchback":
      modelName = "COROLLAHATCHBACK";
      break;
    case "yaris sedan":
      modelName = "YARISSEDAN";
      break;
    case "prius plug-in":
    case "prius plug-in hybrid":
      modelName = "PLUGIN";
      break;
    case "rav4 prime":
      modelName = "RAV4PRIME";
      break;
    case "gr supra":
      modelName = "SUPRA";
      break;
    case "gr86":
      modelName = "86";
      break;
    case "scion f-rs":
      modelName = "fr-s";
      break;
    case "scion ia":
      modelName = "ia";
      break;
    case "scion im":
      modelName = "im";
      break;
    case "scion iq":
      modelName = "iq";
      break;
    case "scion tc":
      modelName = "tc";
      break;
    case "scion xb":
      modelName = "xb";
      break;
    case "scion xd":
      modelName = "xd";
      break;
    case "gr corolla":
      modelName = "GRCOROLLA";
      break;
    case "toyota crown":
      modelName = "TOYOTACROWN";
      break;
    case "toyota crown signia":
      modelName = "TOYOTACROWNSIGNIA"
      break;
    case "grand highlander":
      modelName = "GRANDHIGHLANDER";
      break;
    default:
      break;
  }
  return modelName.toUpperCase();
}

export function getModelName(modelName) {
  if (modelName) {
    modelName = modelName.toLowerCase();
    switch (modelName) {
      case "corollacross":
        modelName = "corolla cross";
        break;
      case "corollahatchback":
        modelName = "corolla hatchback";
        break;
      case "chr":
        modelName = "c-hr";
        break;
      case "priusc": // NOTE that for new vehicles we will need to also update subheader.component:24-45
        modelName = "prius c";
        break;
      case "priusv":
        modelName = "prius v";
        break;
      case "land":
      case "landcruiser":
        modelName = "land cruiser";
        break;
      case "prime":
        modelName = "prius prime";
        break;
      case "corollaim":
        modelName = "corolla im";
        break;
      case "yarishatchback":
        modelName = "yaris hatchback";
        break;
      case "yarisia":
        modelName = "yaris ia";
        break;
      case "supra":
        modelName = "gr supra";
        break;
      case "rav4prime":
        modelName = "rav4 prime";
        break;
      case "grcorolla":
        modelName = "gr corolla";
        break;
      case "toyotacrown":
        modelName = "toyota crown";
        break;
      case "toyotacrownsignia":
        modelName = "toyota crown signia";
        break;
      case "grandhighlander":
        modelName = "grand highlander";
        break;
      default:
        break;
    }
  }
  return modelName;
}

export function getDynamoName(modelName) {
  if (modelName) {
    modelName = modelName.toLowerCase();
    switch (modelName) {
      case "chr":
        modelName = "c-hr";
        break;
      case "corollacross":
        modelName = "corolla cross";
        break;
      case "corollahatchback":
        modelName = "corolla hatchback";
        break;
      case "rav4prime":
        modelName = "rav4 prime";
        break;
      case "priusc":
        modelName = "prius c";
        break;
      case "priusv":
        modelName = "prius v";
        break;
      case "land":
        modelName = "land cruiser"; // This is the old land check and needs to remain in case any users still want to use the LAND url
        break;
      case "landcruiser":
        modelName = "land cruiser";
        break;
      case "prime":
        modelName = "prius prime";
        break;
      case "corollaim":
        modelName = "corolla im";
        break;
      case "yarisia":
        modelName = "yaris ia";
        break;
      case "yarissedan":
        modelName = "yaris sedan";
        break;
      case "plugin":
        modelName = "prius plug-in";
        break;
      case "supra":
        modelName = "gr supra";
        break;
      case "grcorolla":
        modelName = "gr corolla";
        break;
      case "toyotacrown":
        modelName = "toyota crown";
        break;
      case "toyotacrownsignia":
        modelName = "toyota crown signia"
        break;
      case "grandhighlander":
        modelName = "grand highlander";
        break;
      default:
        break;
    }
  }
  return modelName;
}

export function getFormattedName(modelName, uppercase = false) {
  modelName = modelName.toLowerCase();
  switch (modelName) {
    case "bz4x":
      modelName = "bZ4X";
      break;
    case "c-hr":
      modelName = "C-HR";
      break;
    case "chr":
      modelName = "C-HR";
      break;
    case "**chr**":
      modelName = "C-HR";
      break;
    case "**c-hr**":
      modelName = "**C-HR**";
      break;
    case "prius c": // NOTE that for new vehicles we will need to also update subheader.component:24-45
      modelName = "Prius *c*";
      break;
    case "**prius c**":
      modelName = "**Prius *c***";
      break;
    case "land cruiser":
      modelName = "Land Cruiser";
      break;
    case "**land cruiser**":
      modelName = "**Land Cruiser**";
      break;
    case "prime":
      modelName = "Prius Prime";
      break;
    case "**prime**":
      modelName = "**Prius Prime**";
      break;
    case "prius prime":
      modelName = "Prius Prime";
      break;
    case "**prius prime**":
      modelName = "**Prius Prime**";
      break;
    case "corolla im":
      modelName = "Corolla iM";
      break;
    case "**corolla im**":
      modelName = "**Corolla iM**";
      break;
    case "yaris ia":
      modelName = "Yaris iA";
      break;
    case "**yaris ia**":
      modelName = "**Yaris iA**";
      break;
    case "prius v":
      modelName = "Prius *v*";
      break;
    case "**prius v**":
      modelName = "**Prius *v***";
      break;
    case "prius plugin hybrid":
      modelName = "Prius Plug-In Hybrid";
      break;
    case "prius plug-in hybrid":
      modelName = "Prius Plug-In Hybrid";
      break;
    case "plugin":
      modelName = "Prius Plug-In Hybrid";
      break;
    case "plug-in":
      modelName = "Prius Plug-In Hybrid";
      break;
    case "4runner":
      modelName = "4Runner";
      break;
    case "**4runner**":
      modelName = "**4Runner**";
      break;
    case "rav4":
      modelName = "RAV4";
      break;
    case "**rav4**":
      modelName = "**RAV4**";
      break;
    case "rav4 prime":
      modelName = "RAV4 Prime";
      break;
    case "corolla cross":
      modelName = "Corolla Cross";
      break;
    case "**corolla cross**":
      modelName = "**Corolla Cross**";
      break;
    case "Corolla cross":
      modelName = "Corolla Cross";
      break;
    case "corolla hatchback":
      modelName = "Corolla Hatchback";
      break;
    case "**corolla hatchback**":
      modelName = "**Corolla Hatchback**";
      break;
    case "yaris sedan":
      modelName = "Yaris Sedan";
      break;
    case "**yaris sedan**":
      modelName = "**Yaris Sedan**";
      break;
    case "prius plug-in":
      modelName = "Prius Plug-In";
      break;
    case "scion frs":
    case "scion fr-s":
    case "fr-s":
    case "frs":
      modelName = "FR-S";
      break;
    case "scion ia":
    case "ia":
      modelName = "iA";
      break;
    case "scion im":
    case "im":
      modelName = "iM";
      break;
    case "scion iq":
    case "iq":
      modelName = "iQ";
      break;
    case "scion tc":
    case "tc":
      modelName = "tC";
      break;
    case "scion xb":
    case "xb":
      modelName = "xB";
      break;
    case "scion xd":
    case "xd":
      modelName = "xD";
      break;
    case "gr supra":
    case "supra":
      modelName = "GR Supra";
      break;
    case "gr corolla":
      modelName = "GR Corolla";
      break;
    case "86":
    case "gr86":
      modelName = "GR86";
      break;
    case "toyota crown":
      modelName = "Toyota Crown";
      break;
    case "toyota crown signia":
      modelName = "Toyota Crown Signia"
      break;
    case "grand highlander":
      modelName = "Grand Highlander";
      break;
    default:
      modelName = modelName
        ? modelName.charAt(0).toUpperCase() + modelName.slice(1)
        : "";
  }
  if(uppercase) {
    if (!modelName.includes("bZ4X")) {
      modelName = modelName.toUpperCase();
    }
  }
  return modelName;
}

export function getDisplayFormattedModelNameFromUrl(name) {
  const spacedName = getModelName(name);
  return getFormattedName(spacedName);
}

export function getFormattedTrim(trim) {
  switch (trim.toLowerCase()) {
    case "l eco":
      trim = "L *Eco*";
      break;
    case "l eco (fwd 1.8l 4-cyl. hybrid)":
      trim = "L *Eco* (FWD 1.8L 4-Cyl. Hybrid)";
      break;
    case "awd-e":
      trim = "AWD-*e*";
      break;
    case "le awd-e":
      trim = "LE AWD-*e*";
      break;
    case "le (awd-e 1.8l 4-cyl. hybrid)":
      trim = "LE (AWD-*e* 1.8L 4-Cyl. Hybrid) ";
      break;
    case "xle (awd-e 1.8l 4-cyl. hybrid)":
      trim = "XLE (AWD-*e* 1.8L 4-Cyl. Hybrid) ";
      break;
    default:
      break;
  }
  return trim;
}

export function formatModelListItemName(listName, trimName) {
  const formattedName = listName
    .replace(trimName, "")
    .replace("(", "")
    .replace(")", "");

  if (formattedName === "") {
    return trimName;
  } else {
    return formattedName;
  }
}

export function getModelNameForSearchResult(modelName) {
  if (modelName) {
    switch (modelName.toLowerCase()) {
      case "c-hr chr":
        modelName = "C-hr"; // This is a use case for chr index search.
        break;
      default:
        break;
    }
  }
  return modelName;
}

const memorizedValues = {};
export async function getS3PathName(vehicleName) {
  const upperCasedVehicleName = vehicleName.toUpperCase();
  const memorizedValueForModel = memorizedValues[upperCasedVehicleName];
  if (memorizedValueForModel) {
    return Promise.resolve(memorizedValueForModel);
  }
  try {
    const allNonAlphaNumerics = /[\W_]+/g;
    const sameName = (item) =>
      item.s3PathName?.replace(allNonAlphaNumerics, "").toUpperCase() ===
      upperCasedVehicleName.replace(allNonAlphaNumerics, "");
    const findByName = (result) => result.Items.find(sameName) ?? {};
    const { s3PathName } = await getAlternativeVehicleNames().then(findByName);
    memorizedValues[upperCasedVehicleName] = s3PathName;
    return s3PathName;
  } catch (error) {
    console.log("getS3PathName failed", error);
    return Promise.resolve(
      `${upperCasedVehicleName.charAt(0)}${upperCasedVehicleName
        .slice(1)
        .toLowerCase()}`
    );
  }
}
