import { useEffect, useState, useRef, useMemo, useContext } from "react";
import "../../../styles/css/resources-v2/head-to-head.css";
import "../../../styles/scss/resources-v2/head-to-head-print-only.scss";
import "../../../styles/scss/resources-v2/disclaimers-footnotes-print-only.scss";
import { FormattedMessage } from 'react-intl';
import RoundedButton from "../../../components/molecules/roundedButton";
import { getVehiclesAndCompetitorsListV2 } from "../../../services/caEdge.service";
import { isMobileView, isTabletView, isDesktopView } from "../../utils/getScreenSize";
import DropdownV2 from "../../../components/molecules/DropdownV2";
import { Accordion } from "../../../components/molecules/Accordion";
import VideoPlayerComponent from "../../videoPlayer/videoPlayer.component";
import SideBySideV2 from "./sideBySide";
import AccordionLegend from "../../../components/molecules/accordionLegend";
import FeatureAvailabilityRows from "../../../components/molecules/FeatureAvailabilityRows";
import AppSettings from "../../../appsettings";
import applyMarkdown from "../../utils/applyMarkdown";
import DisclaimersComponent from "../../disclaimers/disclaimers.component";
import Skeleton from 'react-loading-skeleton';
import { filterClick, videoPlayerClick, pageLoad } from "./headToHead.analytics";
import {getDynamoName, getS3PathName} from "../../utils/vehicleName";
import { accordionClick } from "../../vehicleDetails/vehicleDetails.analytics";
import AccordionExpandCollapseAllControls from "../../../components/molecules/AccordionExpandCollapseAllControls";
import "../../../styles/scss/vehicleDetails/accordion-expand-collapse.scss"
import { VehiclePageIsPrint } from "../../../components/contexts/vehiclePagePrintContext";

export const HeadToHead = (props) => {
    const [competitorData, setCompetitorData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedComparison, setSelectedComparison] = useState(null);
    const [currentAccordionData, setCurrentAccordionData] = useState(null);
    const [shouldCollapseAllChildren, setShouldCollapseAllChildren] = useState(false);
    const [shouldExpandAllChildren, setShouldExpandAllChildren] = useState(false);
    const [showVideo, toggleShowVideo] = useState(false);
    const [imagePath, setImagePath] = useState(null);
    const [device, setDevice] = useState('desktop');
    const disclaimerComponentRef = useRef();
    const { isPrint } = useContext(VehiclePageIsPrint);

    const updateDevice = () => {
      if (isDesktopView(window.innerWidth)) {
          setDevice('desktop');
      } else if (isTabletView(window.innerWidth)) {
          setDevice('tablet');
      } else if (isMobileView(window.innerWidth)) {
          setDevice('mobile');
      }
    }

    useEffect(async() => {
        getVehiclesAndCompetitorsListV2(`${props.year} ${props.model}`)
          .then((vehiclesCompetitorList) => {
            setCompetitorData(vehiclesCompetitorList?.map((result) => result.competitors).flat());
          })
          .catch((error) => {
            console.log(error);
          }).finally(() => setIsLoading(false))

        const s3PathName = await getS3PathName(getDynamoName(props.model));
        setImagePath(`${AppSettings.AWSImgRepo.edge}${props.year}/${s3PathName}/en/`);

        updateDevice();
        window.addEventListener("resize", updateDevice);
        return () => window.removeEventListener("resize", updateDevice);
    }, []);

    useEffect(() => {
        if (competitorData) {
            setSelectedComparison(competitorData[0]);
        }
    }, [competitorData])

    useEffect(() => {
        if (selectedComparison) {
            const hasCostToOwnData = selectedComparison?.costToOwn?.specs && selectedComparison.costToOwn.specs.length > 1;
            const  hasConnectedServices = selectedComparison?.connectedServices?.specs && selectedComparison.connectedServices.specs.length > 1;

            const accordionData = [
                {
                    title: 'Key Features',
                    body: <div className="accordion-body h2h-key-features">
                        <AccordionLegend type="availability" />
                        {buildKeyFeaturesAccordionBody(selectedComparison.keyFeatures.features)}
                    </div>,
                    key: 1
                },
                {
                    title: 'Quick Specs',
                    body: <div className="accordion-body h2h-quick-specs-features">
                        {buildQuickSpecsAccordionBody(selectedComparison?.quickSpecs?.specs)}
                        <AccordionLegend type="advantage" />
                    </div>,
                    key: 2
                },
                hasConnectedServices && {
                    title: 'Connected Services',
                    body: <div className="accordion-body h2h-quick-specs-features">
                        {buildConnectedServicesAccordionBody(selectedComparison?.connectedServices?.specs)}
                        <AccordionLegend type="advantage" />
                    </div>,
                    key: 3
                },
                hasCostToOwnData && {
                    title: applyMarkdown(selectedComparison?.costToOwn?.specs?.[0]?.header) || "5-Year Cost",
                    body: (
                        <div className="accordion-body h2h-cost-features">
                            {buildCostAccordionBody(selectedComparison.costToOwn.specs.map(s => s.data).flat())}
                            <AccordionLegend type="advantage" />
                        </div>
                    ),
                    key: 4
                }
            ].filter(Boolean); // Remove falsy values (undefined) from the array

            setCurrentAccordionData(accordionData);
        }
    }, [selectedComparison, device]);




    const AdvantageFeature = (props) => {
      return <div className="advantage-feature-detail">
          <div className="advantage">{props.advantage &&
              <img className="advantage-diamond-icon" src={AppSettings.AWSImgRepo.resources + `en/advantage-diamond.svg`}/>}
          </div>
          <div className="feature-name">{applyMarkdown(props?.data, disclaimerComponentRef.current, null, true)}</div>
      </div>
    }

    const buildKeyFeaturesAccordionBody = (data) => {
        const availabilityData = data?.map(k => {
            return {
                "featureName": applyMarkdown(k?.featureName, disclaimerComponentRef.current, null, true),
                "mainData": k.toyotaData,
                "comparisonData": k.competitorData
            }
        })
        return <FeatureAvailabilityRows data={availabilityData} availabilityIcons stacked={device !== 'desktop'} />
    }

    const buildQuickSpecsAccordionBody = (data) => {
        const availabilityData = data?.map(k => {
            return {
                "featureName": applyMarkdown(k?.featureName, null, true),
                "mainData": <AdvantageFeature data={k.toyotaData} advantage={k.toyotaAdvantage} />,
                "comparisonData": <AdvantageFeature data={k.competitorData} advantage={k.competitorAdvantage} />,
            }
        })
        return <FeatureAvailabilityRows data={availabilityData} stacked={device !== "desktop"} />
    }


    const buildConnectedServicesAccordionBody = (data) => {
        const availabilityData = data?.map(k => {
            return {
                "featureName": applyMarkdown(k?.featureName, null, true),
                "mainData": <AdvantageFeature data={k.toyotaData} advantage={k.toyotaAdvantage} />,
                "comparisonData": <AdvantageFeature data={k.competitorData} advantage={k.competitorAdvantage} />,
            }
        })
        return <FeatureAvailabilityRows data={availabilityData} stacked={device !== "desktop"} />
    }


    const buildCostAccordionBody = (data) => {
        const availabilityData = data?.map(k => {
            return {
                "featureName": applyMarkdown(k?.featureName, disclaimerComponentRef.current, null, true),
                "mainData": <AdvantageFeature data={k.toyotaData} advantage={k.toyotaAdvantage} />,
                "comparisonData": <AdvantageFeature data={k.competitorData} advantage={k.competitorAdvantage} />
            }
        })
        return <FeatureAvailabilityRows data={availabilityData} stacked={device !== "desktop"} />
    }

    const OverviewVideoSection = () => {
        const handleVideoRowClick = (clickedVideoRow, isExpand) => {
            accordionClick({
                page: `${props.pageData?.parentTitle ? props.pageData.parentTitle + ":" : ""}${props.pageData?.title}`,
                term: clickedVideoRow,
                isExpand: isExpand,
                modelName: props.model,
                modelYear: props.year
            });
        };
        return selectedComparison?.headToHeadHeader?.qumuId ? <>
            <div className="video-row">
                <div onClick={() => {
                    toggleShowVideo(!showVideo);
                    handleVideoRowClick('Video Overview', !showVideo); // Pass the value to handleVideoClick for analytics
                }} className="video-row-header">
                    <div className={`video-row-title ${showVideo && 'expanded'}`}>
                        <span>Video Overview</span>
                    </div>
                    <div className="video-row-expand-collapse-icon">
                        {showVideo ? '－' : '＋'}
                    </div>
                </div>
            </div>
            {showVideo &&
            <div className="overview-video-section">
                <div className="video-container" onClick={handleVideoPlayerClick}>
                    <VideoPlayerComponent
                        item={{
                            hideCTA: true,
                            qumuId: selectedComparison?.headToHeadHeader.qumuId,
                        }}
                    />
                </div>
            </div>
            }
        </> : <></>
    }

    const getCompetitorFilter = () => {
        return (
            <div className="competitor-filter">
                <div className="filter-label">Competitor:</div>
                {competitorData ? (device === "mobile" && !isPrint ?
                    <DropdownV2
                        style={"white"}
                        options={competitorData?.map((competitor) => competitor.competitorVehicle.model)}
                        value={selectedComparison?.competitorVehicle.model}
                        onValueChange={(event) => handleFilterClick(event.value)}
                    />
                    : competitorData?.map((competitor) => competitor.competitorVehicle.model)?.map(name => {
                        return (
                            <RoundedButton
                                id="competitor-filter-button-print-only"
                                title={name}
                                onClick={() => handleFilterClick(name)}
                                active={selectedComparison?.competitorVehicle.model == name}
                                color={"#00000000"}
                                activeColor={"#00000000"}
                                borderColor={selectedComparison?.competitorVehicle.model == name ? "#000000" : "#00000000"}
                                textColor={"#000000"}
                                height={"35px"}
                            />
                        )
                    })
                ) : <Skeleton containerClassName={"skeleton-section"} count={3} width={100} height={30} inline />}
            </div>
        )
    }

    const handleFilterClick = (clickedCompetitor) => {
        // Handle click from competitor filter
        // Also update the selected comparison
        const selectedComparisonToUpdate = competitorData?.find((comparison) => comparison.competitorVehicle.model === clickedCompetitor);
        setSelectedComparison(selectedComparisonToUpdate);

        filterClick({
            page: `${props.pageData?.parentTitle ? props.pageData.parentTitle + ":" : ""}${props.pageData?.title}`,
            modelName: props?.model,
            modelYear: props.year,
            contentTitle: clickedCompetitor
        })
    };

    const handleVideoPlayerClick = () => {
        videoPlayerClick({
            page: `${props.pageData?.parentTitle ? props.pageData.parentTitle + ":" : ""}${props.pageData?.title}`,
            modelName: props?.model,
            modelYear: props.year,
        })
    };

    const handleExpandAllAccordionRows = () => {
        toggleShowVideo(true)
        setShouldExpandAllChildren(new Date().getTime())
    }

    const handleCollapseAllAccordionRows =() => {
        toggleShowVideo(false)
        setShouldCollapseAllChildren(new Date().getTime())
    }

    const renderDisclaimerComponent = useMemo(() => {
        console.log('competitor: ', selectedComparison?.competitorVehicle);
        return selectedComparison?.competitorVehicle ?
            <DisclaimersComponent
                template={`${props?.year}_${props?.model}_${selectedComparison?.competitorVehicle.year} ${encodeURIComponent(selectedComparison?.competitorVehicle.model.toLowerCase().replace(/\//g, '_'))}`}
                ref={disclaimerComponentRef}
            /> : <></>
    }, [selectedComparison?.competitorVehicle, props?.year, props?.model])

    return (
        <div className="head-to-head-component">
            {(currentAccordionData || isLoading) && <div className="head-to-head-header" id="head-to-head-header-print-only">
                {getCompetitorFilter()}
                <SideBySideV2
                    selectedComparison={selectedComparison}
                    year={props.year}
                    imagePath={imagePath}
                />
            </div>}

            {(currentAccordionData && !isLoading) &&
                <div className="combined-accordion">
                    <div className="accordion-header">
                        <AccordionExpandCollapseAllControls
                            expandAll={handleExpandAllAccordionRows}
                            collapseAll={handleCollapseAllAccordionRows}
                        />
                    </div>
                    <OverviewVideoSection />
                    <Accordion
                        shouldExpandAllChildren={shouldExpandAllChildren}
                        shouldCollapseAllChildren={shouldCollapseAllChildren}
                        titleStyling={props?.outerTitleStyling}
                        rows={currentAccordionData}
                        className="vehicle-page-accordion"
                        termAnalyticsClick={(term, isExpand) => {
                            accordionClick({
                                term: term,
                                isExpand: isExpand,
                                modelName: props.model,
                                modelYear: props.year
                            });
                        }}
                    />
                </div>
            }

            {(disclaimerComponentRef?.current && !isLoading) && <div className="disclaimers-footnote" id="disclaimers-footnote-print-only">
                <div className='disclaimers-title'>Disclaimers</div>
                <p>{applyMarkdown(Object.values(disclaimerComponentRef.current?.disclaimers).map((disclaimer, index) => `<b>${index+1}.</b>&nbsp;<text>${disclaimer}</text>`).join('&nbsp;'))}</p>
            </div>}

            {(!currentAccordionData && isLoading) && <Skeleton count={5} height={30}/>}

            {(!currentAccordionData && !isLoading) && <div className="noResponse"><FormattedMessage id='caHeadToHead.noComparisonAvailable' color='white'/></div>}

            {renderDisclaimerComponent}
        </div>
    );
};
