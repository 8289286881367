function capitalize(string) {
  const stringToFormat = string.toLowerCase();
  let formattedString;
  switch (stringToFormat) {
    case '86':
      formattedString = '86';
      break;
    case 'rav4':
      formattedString = stringToFormat.toUpperCase();
      break;
    case 'bz4x':
      formattedString = 'bZ4X';
      break;
    case 'c-hr':
      formattedString = 'C-HR';
      break;
    case 'corolla im':
      formattedString = stringToFormat.charAt(0).toUpperCase() + stringToFormat.slice(1);
      formattedString = stringToFormat.replace('im', 'iM');
      break;
    case 'toyota crown':
      formattedString = 'Toyota Crown';
      break;
    case 'toyota crown signia':
      formattedString = 'Toyota Crown Signia';
      break;
    case '4runner':
      formattedString = '4Runner';
      break;
    case 'yaris ia':
      formattedString = 'Yaris iA';
      break;
    case 'prius prime':
      formattedString = 'Prius Prime';
      break;
    case 'yaris sedan':
      formattedString = 'Yaris Sedan';
      break;
    case 'land cruiser':
      formattedString = 'Land Cruiser';
      break;
    case 'corolla cross':
      formattedString = 'Corolla Cross';
      break;
    case 'corolla hatchback':
      formattedString = 'Corolla Hatchback';
      break;
    case 'prius c':
      formattedString = 'Prius c';
      break;
    case 'prius *c*':
      formattedString = 'Prius c';
      break;
    case 'supra':
    case 'gr supra':
      formattedString = 'GR Supra';
      break;
    case 'gr corolla':
      formattedString = 'GR Corolla';
      break;
    case 'rav4 prime':
      formattedString = 'RAV4 Prime';
      break;
    case 'gr86':
      formattedString = 'GR86';
      break;
    case 'grand highlander':
      formattedString = 'Grand Highlander';
      break;
    default:
      formattedString = stringToFormat.charAt(0).toUpperCase() + stringToFormat.slice(1);
  }
  return formattedString;
}

function capitalizeTitle(string) {
  const stringArray = string.split(' ');
  const cleanedWords = [];
  stringArray.forEach((word) => {
    const lowerCaseWord = word.toLowerCase();
    const cleanedWord = lowerCaseWord.charAt(0).toUpperCase() + lowerCaseWord.slice(1);
    cleanedWords.push(cleanedWord);
  });
  return cleanedWords.join(' ');
}

function capFirst(string) {
  const stringToFormat = string;
  return stringToFormat.charAt(0).toUpperCase() + stringToFormat.slice(1);
}

function capFirstAndRestLowerCase(string) {
  const split = string.split(' ');
  let reformattedString = '';
  split.forEach((subString) => {
    reformattedString += `${subString.charAt(0).toUpperCase() + subString.slice(1).toLowerCase()} `;
  });
  return reformattedString;
}

module.exports.capitalize = capitalize;
module.exports.capitalizeTitle = capitalizeTitle;
module.exports.capFirst = capFirst;
module.exports.capFirstAndRestLowerCase = capFirstAndRestLowerCase;
