import { GlobalAnalytics } from "../../../analytics";

const analytics = new GlobalAnalytics();

// Page Load
export const profilePagePageLoad = (pageLoad) => {
  if(pageLoad) {
    analytics.firePageLoad({
      app: pageLoad.appId,
      tag: pageLoad.tagId,
      section: pageLoad.section,
      page: pageLoad.page,
      subsection: pageLoad.subsection,
      technologyName: pageLoad.technology_name,
    });
  }
};

export const privacyNoticeClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: '28.2',
      app: 'eShow',
      container: "Profile",
      category: "Profile Page",
      label: label
    });
  }
};
export const privacyChoicesClick = (clickAnalytics, label) => {
  if (clickAnalytics) {
    analytics.fireOnClick({
      tag: '28.2',
      app: 'eShow',
      container: "Profile",
      category: "Profile Page",
      label: label
    });
  }
};
