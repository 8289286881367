import React from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import ReactModal from 'react-modal';
import '../../styles/css/disclaimers.css';
import applyMarkdown from '../utils/applyMarkdown';

ReactModal.setAppElement('#root')

export const DisclaimersTemplate = (component) => {
	const { state, onCloseModal, onAfterOpenModal } = component;
	const { modalOpen } = state;

	const modalProps = {
		isOpen: modalOpen,
		onRequestClose: onCloseModal,
		className: "disclaimer-modal-content",
		overlayClassName: "disclaimer-modal-overlay",
		shouldFocusAfterRender: true,
		shouldCloseOnOverlayClick: true,
		onAfterOpen: onAfterOpenModal,
		preventScroll: true,
		parentSelector: () => document.querySelector('#modalPortal')
	}

	return state.loading ? (
		<>
			<div style={{
				marginLeft: -50
			}}>
				<SkeletonTheme inline height={455} width={window.innerWidth + 100}>
					<Skeleton />
				</SkeletonTheme>
			</div>
			<SkeletonTheme height={455}>
				<Skeleton />
			</SkeletonTheme>
			<div style={{
				display: 'flex',
				flexDirection: 'column',
			}}>
				<div style={{ paddingLeft: (window.innerWidth / 2) - 100 }}>
					<SkeletonTheme width={200} height={80}>
						<Skeleton />
					</SkeletonTheme>
				</div>
			</div>
			<SkeletonTheme inline width={(window.innerWidth / 3) - 30} height={455}>
				<Skeleton wrapper={PictureWrapper} />
				<Skeleton wrapper={PictureWrapper} />
				<Skeleton wrapper={PictureWrapper} />
			</SkeletonTheme>
		</>
	) : (
		<ReactModal {...modalProps}>
			<ModalTitle />
			<DisclaimerList component={component} />
			<CloseButton onCloseModal={onCloseModal} />
		</ReactModal>
	);
}

const ModalTitle = () => {
	return (
		<div className="disclaimers-modal-title">
			<FormattedMessage id="disclaimers.pageTitle" />
		</div>
	)
}

const DisclaimerList = (props) => {
	const { component } = props;
	const { disclaimers } = component;
	const { nonTemplateDisclaimers } = component;

	const nonTemplateDisclaimerList = Object.keys(nonTemplateDisclaimers).map((currentDisclaimer) => (
		<DisclaimerItem
			component={component}
			currentDisclaimer={currentDisclaimer}
			nonTemplate={true} />
	));

	const disclaimerList = Object.keys(disclaimers).map((currentDisclaimer) => (
		<DisclaimerItem
			component={component}
			currentDisclaimer={currentDisclaimer} />
	));

	const allDisclaimers = nonTemplateDisclaimerList.concat(disclaimerList);

	return (
		<ul className="disclaimers-list">
			{allDisclaimers}
		</ul>
	);
}

const DisclaimerItem = (props) => {
	const { currentDisclaimer, component, nonTemplate } = props;
	const { disclaimers, nonTemplateDisclaimers, state } = component;
	const { selectedDisclaimer } = state;

	const generateText = () => {
		if(nonTemplate) {
			return <>&dagger;. {applyMarkdown(nonTemplateDisclaimers[currentDisclaimer])}</>
		} else {
			return <>{currentDisclaimer}. {applyMarkdown(disclaimers[currentDisclaimer])}</>
		}
	}

	return (
		<li
			id={`disclaimer-number-${currentDisclaimer}`}
			key={currentDisclaimer}
			className={addSelectedDisclaimerClassIfNecessary(selectedDisclaimer, currentDisclaimer)} >
			{generateText()}
		</li>
	)
}

const addSelectedDisclaimerClassIfNecessary = (selectedDisclaimer, currentDisclaimer) => {
	const className = disclaimerIsSelected(selectedDisclaimer, currentDisclaimer) ? 'selected-disclaimer' : '';
	return className;
}

const disclaimerIsSelected = (selectedDisclaimer, currentDisclaimer) => {
	return parseInt(selectedDisclaimer, 10) === parseInt(currentDisclaimer, 10);
}

const CloseButton = (props) => {
	const svgPath = "M28.5 9.62L26.38 7.5 18 15.88 9.62 7.5 7.5 9.62 15.88 18 7.5 26.38l2.12 2.12L18 20.12l8.38 8.38 2.12-2.12L20.12 18z";
	const svgClass = "styles_closeIcon__1QwbI";
	const { onCloseModal } = props;

	return (
		<button className="disclaimers-close-button"
			onClick={() => onCloseModal()}>
			<svg className={svgClass}
				xmlns="http://www.w3.org/2000/svg"
				width="28"
				height="28"
				viewBox="0 0 36 36">
				<path d={svgPath} />
			</svg>
		</button>
	)
}

const PictureWrapper = ({ children }) => (
	<span style={{ paddingLeft: 10 }}>
		{children}
	</span>
)
