import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Dropdown from "react-dropdown";
import "../../styles/scss/dropdownV2.scss";
import AppSettings from "../../appsettings";

// During development of the 2023 Vehicle Page Upgrade a new type of dropdown was
// needed that the was different enough from dropdownV2 to merit more than just
// css style changes. This is refered to as the fullBackground variant. This new variant
// can be reused elsewhere and the value field is capable of taking in jsx instead
// of just text. The rest of the functionality should mirror dropdownV2.

//////////////////////////////////////////////////////////////////////////////
//// DOCUMENTATION FOUND AT  https://www.npmjs.com/package/react-dropdown ////
//////////////////////////////////////////////////////////////////////////////
const DropdownV2 = (props) => {
  let dropdownStyles = [
    "default",
    "grey",
    "black-red",
    "black",
    "dark-grey",
    "dark-translucent",
    "white",
    "disabled",
    "fullBackground",
    "white-translucent"
  ];

  const dropdownContainerRef = useRef(null);
  const dropdownRef = useRef(null);
  const disabled = props.disabled || props.style == "disabled";
  const [value, setValue] = useState(null);

  // Only used for VP Subheader variant
  const [open, setOpen] = useState(false);

  const useClickOutside = (ref, callback) => {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current?.contains(event.target)) return;
        if (callback) callback(event);
      };

      document.addEventListener("touchstart", listener);

      return () => {
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, callback]);
  };


  useClickOutside(dropdownContainerRef, () => {
    const currentRef = dropdownRef.current;
    if (currentRef) {
      const dropdownState = currentRef.state;
      if (dropdownState.isOpen) {
        currentRef.setState({
          ...dropdownState,
          isOpen: false,
        });
      }
    }
  });


  useEffect(() => {
    if (props.value || props.defaultValue) {
      props.value ? setValue(props.value) : setValue(props.defaultValue);
    }
  }, [props.value, props.defaultValue]);

  const blackArrow =
    AppSettings.AWSImgRepo.resources + "en/Down_Arrow_Black.png";
  const redArrow = AppSettings.AWSImgRepo.resources + "en/chevron_down_red.png";

  // Only used for VP Subheader variant
  // Needs to use differnt logic than the rest of the variants due to color change
  const whiteArrow =
    AppSettings.AWSImgRepo.resources + "en/Down_Arrow_White.png";
  const greyArrow = AppSettings.AWSImgRepo.resources + "en/Up_Arrow_Grey.png";

  const getDefaultStyleArrow = () => {
    if (dropdownStyles.includes(props.style)) {
      switch (props.style) {
        case "black":
        case "grey":
        case "dark-grey":
        case "dark-translucent":
        case "white":
        case "disabled":
          return blackArrow;
        case "black-red":
        case "default":
          return redArrow;
        case "white-translucent":
          return whiteArrow;
        default:
          return redArrow;
      }
    } else return redArrow;
  };

  const dropdownClosedDefaultStyleArrow = (
    <img className="dropdown-arrow" src={getDefaultStyleArrow()} />
  );
  const dropdownOpenDefaultStyleArrow = (
    <img className="dropdown-arrow upside-down" src={getDefaultStyleArrow()} />
  );

  const getClassNames = () => {
    if (dropdownStyles.includes(props.style)) {
      return { className: `${props.style} ${props.rootClassName}` };
    } else if (props.placeholderClassName || props.menuClassName) {
      return {
        placeholderClassName: props.placeholderClassName,
        menuClassName: props.menuClassName,
      };
    } else return { className: "default" };
  };

  if (props.style != "fullBackground") {
    return (
      <div ref={dropdownContainerRef} style={{width: '100%'}}>
        <Dropdown
          ref={dropdownRef}
          arrowClosed={
            props.arrowClosed
              ? props.arrowClosed
              : dropdownClosedDefaultStyleArrow
          }
          arrowOpen={
            props.arrowOpen ? props.arrowOpen : dropdownOpenDefaultStyleArrow
          }
          disabled={disabled}
          options={props.options}
          onChange={props.onValueChange}
          placeholder={props.placeholder}
          value={!disabled && value} //if disabled, defaults to show placeholder. Pass placeholder if disabled to override default "Select..." placeholder
          {...getClassNames()}
        />
      </div>
    );
  } else {
    return (
      <>
        {open && <div className="dropdown-overlay" />}
        <div
          className="fullBackground-dropdown"
          tabIndex={0}
          onBlur={() => setOpen(false)}
        >
          <div
            className={open ? "value-section open" : "value-section"}
            onClick={() => (!props.disabled ? setOpen(!open) : null)}
          >
            {!props.disabled
              ? props.value
              : props.placeholder
              ? props.placeholder
              : "Select ..."}
            <img className="arrow" src={open ? greyArrow : whiteArrow} />
          </div>
          {open && (
            <div className="menu">
              {props.options.map((option, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setOpen(false)
                    props?.onValueChange(option)
                  }}
                  className="option"
                >
                  {option.label ? option.label : option}
                </div>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
};

DropdownV2.propTypes = {
  arrowClosed: PropTypes.oneOfType([
    PropTypes.instanceOf(Element),
    PropTypes.instanceOf(Object),
    PropTypes.bool,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  disabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.number, PropTypes.object, PropTypes.string])
  ),
  placeholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholderClassName: PropTypes.string,
  style: PropTypes.string,
};

export default DropdownV2;
