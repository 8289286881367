import 'whatwg-fetch';
import httpOption from './httpOption';
import AppSettings from '../appsettings';
import CryptoJS from 'crypto-js';
import { VSPEC_ENDPOINT } from '../constants/endpoints';

export function getVspecToken(language) {
  
  const url = `${VSPEC_ENDPOINT}/getVspecToken`;
  const options = httpOption.GET();
  options.headers['accept-language'] = language || options.headers['accept-language'];

  return fetch(url, options)
    .then((response) => {
      if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response.json());
      }
      return Promise.reject(response);
    });
}


export function generateSignature(vin,dealerCd, hashKey) {

    const hashValue = dealerCd ? dealerCd + vin : vin;
    const hashVal = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(hashValue, hashKey));
    console.log(`${AppSettings.vspecUrl}/v-spec/${dealerCd}/${vin}/detail/download?k=${hashVal}`);
}

export async function getVspecPdf(vin) {
  const url = `${VSPEC_ENDPOINT}/pdf/${vin}`;
  const options = httpOption.GET();
  options.headers['accept-language'] = options.headers['accept-language'];

  const response = await fetch(url, options)
  if (response.status >= 200 && response.status < 300) {
    if(response.headers.get("Content-Type").includes("application/octet-stream")) {
      const blob = await response.blob();
      return Promise.resolve({ blob });
    }
    const json = await response.text();
    return Promise.resolve({ json });
  }
  return Promise.reject(response);
}

export async function downloadVspecPdf(vin) {
  const data = await getVspecPdf(vin);
  if(data.blob) {
    const blob = new Blob([data.blob], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    
    const a = document.createElement('a');
    a.href = url;
    a.download = `${vin}-vspec.pdf`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url); 
  } else {
    console.log("JSON response:", data.json)
  }
}