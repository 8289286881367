import React, { useState, useEffect } from "react";
import ColorSelector from "./colorSelector";
import "../../../styles/scss/vehicleDetails/colors.scss";
import appsettings from "../../../appsettings";
import AppSettings from '../../../appsettings';
import ViewLargerImages from "../../../components/molecules/viewLargerImages";
import Skeleton from "react-loading-skeleton";
import { postColorAction } from "../../../services/nitroService";

import { toggleLargeImages, colorClick, largeModalButtonClick } from './colors.analytics'

const Colors = (props) => {
    const [allExteriorColors, setAllExteriorColors] = useState(); // use to display list/tooltips of exterior color swatches
    const [allInteriorColors, setAllInteriorColors] = useState(); // use to display list/tooltips of interior color swatches
    const [availableExtColorCodes, setAvailableExtColorCodes] = useState(); // use to control which exterior swatches are enabled/disabled
    const [selectedExtColor, setSelectedExtColor] = useState(); // use to display selected exterior color details/image/check mark
    const [availableIntColorCodes, setAvailableIntColorCodes] = useState(); // use to control which interior color swatches are enabled/disabled
    const [selectedIntColor, setSelectedIntColor] = useState(); // use to display selected interior color details/image/check mark
    const [exteriorImage, setExteriorImage] = useState({ src: '', error: false }); // use to display exterior image and handle its errors
    const [interiorImage, setInteriorImage] = useState({ src: '', error: false }); // use to display interior image and handle its errors

    useEffect(() => {
        if (props.trims && props.trimOptions && props.selectedTrim) {
            // exterior colors available for the selected trim
            const selectedTrimColors = props.trims?.find(t => t.name === props.selectedTrim?.name)?.colorsAvailable;

            // unique, sorted list of all exterior colors data prioritizing selected trim for its image
            const uniqueExteriorColors = {};
            props.trims?.flatMap(t => t.colorsAvailable)?.forEach(c => {
                const selectedTrimMatch = selectedTrimColors?.find(e => e.paintCode === c.paintCode)
                if (selectedTrimMatch) {
                    uniqueExteriorColors[c.paintCode] = selectedTrimMatch;
                } else {
                    uniqueExteriorColors[c.paintCode] = c;
                }
            })
            const exteriorColors = Object.values(uniqueExteriorColors)
                ?.sort((a, b) => a.paintCodeName.localeCompare(b.paintCodeName));
            setAllExteriorColors(exteriorColors);

            // list of available exterior color codes
            const trimExtColors = selectedTrimColors?.map(c => c.paintCode);
            setAvailableExtColorCodes(trimExtColors);

            // if selected color available on new trim, get new color data for the trim's image
            if (trimExtColors?.includes(selectedExtColor?.paintCode)) {
                const extColor = exteriorColors?.find(e => e.paintCode === selectedExtColor?.paintCode)
                setSelectedExtColor(extColor);
            } else {
                // if selected color unavailable on new trim, select first available exterior color
                setSelectedExtColor(exteriorColors?.find(e => trimExtColors?.includes(e.paintCode)));
            }

            // sorted list of all interior colors data
            const interiorColors = props.trimOptions?.interiorColors && Object.keys(props.trimOptions?.interiorColors)
                ?.map(k => ({ code: k, ...props.trimOptions?.interiorColors[k] }))
                ?.sort((a, b) => a.name.localeCompare(b.name))
            setAllInteriorColors(interiorColors);
        }
    }, [props.trims, props.trimOptions, props.selectedTrim]);

    useEffect(() => {
        // list of available interior color codes, based on selected exterior color
        setAvailableIntColorCodes(selectedExtColor?.intColors)
        // if not already selected, select first available interior color
        if (!selectedExtColor?.intColors?.includes(selectedIntColor?.code)) {
            setSelectedIntColor(allInteriorColors?.find(i => selectedExtColor?.intColors?.includes(i.code)))
        }
    }, [selectedExtColor]);

    useEffect(() => {
        setExteriorImage({
            src: appsettings.AWSImgRepo.vehicle + props.year + "/" + props.s3PathName + "/en/" + selectedExtColor?.imageFilename,
            error: false,
        });
    }, [selectedExtColor, props.s3PathName]);

    useEffect(() => {
        setInteriorImage({
            src: appsettings.AWSImgRepo.vehicle + props.year + "/" + props.s3PathName + "/en/" + selectedIntColor?.image,
            error: false,
        });
    }, [selectedIntColor, props.s3PathName]);


    const onExteriorImageError = () => {
        if (!exteriorImage.error) {
            setExteriorImage({
                src: appsettings.AWSImgRepo.resources + 'en/image_coming_soon_placeholder.png',
                error: true,
            });
        }
    }

    const onInteriorImageError = () => {
        if (!interiorImage.error) {
            setInteriorImage({
                src: appsettings.AWSImgRepo.resources + 'en/image_coming_soon_rectangle_placeholder.png',
                error: true,
            });
        }
    }

    const selectExteriorColor = (color) => {
        setSelectedExtColor(color);
        postColorAction("Exterior Color Swatch", color?.paintCode);
    }

    const selectInteriorColor = (color) => {
        setSelectedIntColor(color);
        postColorAction("Interior Color Swatch", color?.code);
    }
    
    return (
        <div>
            {!props.colorOptionsLoading && props?.trims && props.s3PathName? ( 
            <div className="color-viewer-selector-container">
                    <div className="exterior-image">
                        <img src={exteriorImage.src} onError={onExteriorImageError} />
                    </div>
                    <div className="interior-image">
                        <img src={interiorImage.src} onError={onInteriorImageError} />
                    </div>
                    <div className="view-large-image-button">
                        <ViewLargerImages
                            toggleAnalytics={toggleLargeImages}
                            clickAnalytics={largeModalButtonClick}
                            images={[exteriorImage.src, interiorImage.src]}
                            pageTitle={props?.title}
                            model={props?.model}
                            year={props?.year}
                            trim={props.selectedTrim?.name}
                        />
                    </div>
                    <div className="exterior-selector">
                        <ColorSelector
                            s3Path={props.year + "/" + props.s3PathName + "/en/"}
                            options={allExteriorColors}
                            availableOptions={availableExtColorCodes}
                            selectedOption={selectedExtColor}
                            onSelect={selectExteriorColor}
                            clickAnalytics={colorClick}
                            pageTitle={props?.title}
                            model={props?.model}
                            year={props?.year}
                            trim={props.selectedTrim?.name}
                        />
                    </div>
                    <div className="interior-selector">
                        <ColorSelector
                            s3Path={props.year + "/" + props.s3PathName + "/en/"}
                            type="interior"
                            options={allInteriorColors}
                            availableOptions={availableIntColorCodes}
                            selectedOption={selectedIntColor}
                            onSelect={selectInteriorColor}
                            clickAnalytics={colorClick}
                            pageTitle={props?.title}
                            model={props?.model}
                            year={props?.year}
                            trim={props.selectedTrim?.name}
                        />
                    </div>
                </div>
                ):( 
                <div className="colors-skeleton">
                    <div className="colors-skeleton-image-section">
                        <Skeleton className="skeleton-item" />
                        <Skeleton className="skeleton-item" />
                    </div>

                    <div className="colors-skeleton-selector-section">
                        <Skeleton className="skeleton-item" />
                        <Skeleton className="skeleton-item" />
                    </div>
                </div>)}

        </div>
    )
}

export default Colors;