import * as _ from 'lodash';
import '../../styles/css/homepage/homepage.css'
import FeaturedCarousel from './featuredCarousel/featuredCarousel';
import ExploreCarousel from './exploreCarousel/exploreCarousel';
import DatesCarousel from './datesCarousel/datesCarousel';
import React, { useEffect, useState } from 'react';
import { Hero } from './homepage.hero';
import QuickLinks from "./quickLinks";
import getMisc from './../../services/miscService';
import { isDesktopView, isTabletView, isMobileView } from '../utils/getScreenSize';
import FeaturedVehicle from "./featuredVehicle";
import getAnalytics from '../../services/analyticsService';
import { homepageLoaded } from './homepage.analytics';
import { postViewPageAction } from '../../services/nitroService';
import { downloadVspecPdf } from '../../services/vspecService';

const HomepageComponent = (props) => {
    const [device, setDevice] = useState('desktop');
    const [pageData, setPageData] = useState('');
    const [screenSize, setScreenSize] = useState(0);

    // TODO: remove this after VSPEC work is done
    const [vin, setVin] = useState('');

    const [analytics, setAnalytics] = useState(null);

    useEffect(() => {
        updateDevice();
        getHomepageAnalytics();
        window.addEventListener("resize", updateDevice);
        return () =>
            window.removeEventListener("resize", updateDevice);
    }, []);

    useEffect(() => {
        getMisc('HomepageV3', 'en')
            .then((data) => {
                setPageData(data.items);
            })
            .catch((error) => {
                console.log(`ERROR: failed to load homepage data. ${error}`);
            });
        postViewPageAction();
    }, []);

    useEffect(() => {
        analytics && homepageLoaded(analytics.pageLoad);
    }, [analytics]);

    const updateDevice = () => {
        setScreenSize(window.innerWidth);

        if (isDesktopView(window.innerWidth)) {
            setDevice('desktop');
        } else if (isTabletView(window.innerWidth)) {
            setDevice('tablet');
        } else if (isMobileView(window.innerWidth)) {
            setDevice('mobile');
        }
    }

    const getHomepageAnalytics = async () => {
        const homepageAnalyticsRawData = await getAnalytics('homepage');
        setAnalytics(homepageAnalyticsRawData);
    }

    return (
        <div className="homepageContainer">
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '20px'
            }}>
                <span style={{ fontSize: '20px' }} >
                    Input VIN: <span style={{ fontSize: '12px' }}>(Used vehicle example: 2T3A1RFV3KW060751)</span>
                </span>
                <input
                    style={{
                        fontSize: '20px',
                        border: '1px solid black',
                        padding: '10px',
                        margin: '10px',
                        borderRadius: '5px',
                        width: '300px'
                    }} type="text"
                    onChange={e => setVin(e.target.value)}
                />
                <button
                    style={{
                        fontSize: '20px',
                        border: '1px solid black',
                        padding: '10px',
                        margin: '10px',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        backgroundColor: 'aqua'
                    }}
                    onClick={() => {
                        // 2T3A1RFV3KW060751
                        downloadVspecPdf(vin);
                    }}>
                    Download {vin} VSPEC PDF
                </button>
            </div>
            {pageData?.hero && <Hero
                desktopTabletImage={pageData.hero.desktopTabletImage}
                mobileImage={pageData.hero.mobileImage}
                badgeColor={pageData.hero.badgeColor}
                badgeTextColor={pageData.hero.badgeTextColor}
                badgeText={pageData.hero.badgeText}
                contentColor={pageData.hero.contentColor}
                smallTitle={pageData.hero.smallTitle}
                largeTitle={pageData.hero.largeTitle}
                description={pageData.hero.description}
                buttonText={pageData.hero.buttonText}
                buttonLink={pageData.hero.buttonLink}
                heroLink={pageData.hero.heroLink}
                overlayGradient={pageData.hero.overlayGradient}
                analytics={analytics ? analytics.clickAnalytics.hero : null}
            />}

            {pageData?.quickLinks?.length > 0 && <QuickLinks
                quickLinkButtons={pageData.quickLinks}
                analytics={analytics ? analytics.clickAnalytics.quickLinks : null}
            />}

            {pageData?.featuredContent?.length > 0 && <FeaturedCarousel
                featuredContent={pageData.featuredContent}
                device={device}
                analytics={analytics ? analytics.clickAnalytics.featuredContent : null}
            />}

            {pageData?.engageContent && pageData?.engageContent?.content?.length > 0 && <ExploreCarousel
                title={pageData.engageContent.title}
                categories={pageData.engageContent.categories}
                content={pageData.engageContent.content}
                device={device}
                analytics={analytics ? analytics.clickAnalytics.exploreContent : null}
            />}

            {pageData?.featuredVehicles?.length > 0 && <FeaturedVehicle
                featuredVehicles={pageData.featuredVehicles}
                analytics={analytics ? analytics.clickAnalytics.featuredVehicle : null}
            />}

            {pageData?.datesToRemember?.length > 0 && <DatesCarousel
                title={'Dates To Remember'}
                dateList={pageData.datesToRemember}
                device={device}
                screenSize={screenSize}
            />}

        </div>
    );
};

export default HomepageComponent;
