import { GlobalAnalytics } from "../../analytics";

const analytics = new GlobalAnalytics();

export function modelYearDropdownClick(event) {
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: event?.modelName,
    category: "Sub Nav",
    label: "Model Year Dropdown",
    modelName: event?.modelName,
    modelYear: event?.modelYear,
    contentTitle: event?.modelYear,
  });
}

export function subNavClick(event) {
  const {pageTitle, navTitle, ...customData} = event;
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: pageTitle,
    category: "Sub Nav",
    label: navTitle,
    ...customData
  });
}

export function subNavDropdownClick(event) {
  const {pageTitle, navTitle, ...customData} = event;
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: pageTitle,
    category: "Sub Nav",
    label: "Dropdown",
    ...customData
  });
}

export function bookmarkClick(event, add) {
  const {pageTitle, navTitle, ...customData} = event;
  analytics.fireOnClick({
    tag: "29.2",
    section: "Product",
    page: pageTitle,
    category: "Sub Nav",
    label: `${add ? "Add" : "Remove"} Bookmark`,
    ...customData
  });
}
