import 'whatwg-fetch';
import httpOption from './httpOption';
import { VIN_LOOKUP_ENDPOINT } from '../constants/endpoints';

export function postVinLookup(vin) {
  const url = `${VIN_LOOKUP_ENDPOINT}/${vin}`;
  const options = httpOption.POST();
  options.headers['accept-language'] = options.headers['accept-language'] || 'en';

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return Promise.reject(response);
      });
  });
}

export function checkVin(vin) {
  const url = `${VIN_LOOKUP_ENDPOINT}/check/${vin}`;
  const options = httpOption.POST();
  options.headers['accept-language'] = options.headers['accept-language'] || 'en';

  return new Promise((resolve, reject) => {
    fetch(url, options)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return resolve(response.json());
        }
        return reject(response);
      });
  });
}